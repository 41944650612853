import styled from "styled-components";

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  box-sizing: border-box;
  background: radial-gradient(
    circle,
    rgba(20, 67, 97, 1) 0%,
    rgba(58, 214, 160, 1) 100%
  );
  p {
    max-width: 700px;
  }
`;

const Title = styled.p`
  font-family: "Pacifico";
  font-weight: 500;
  font-size: 35px;
  color: white;
  text-align: center;
  margin: 30px auto;
`;

const Description = styled.p`
  font-weight: 300;
  font-size: 25px;
  color: white;
  text-align: center;
  margin-bottom: 50px;
  span {
    font-size: 25px;
    font-weight: 700;
  }
`;

export default function Main() {
  return (
    <MainContainer id="help">
      <Title>¡Gracias por tu generosidad!</Title>
      <Description>
        Creemos que una comunidad no se construye sobre el sacrificio de pocos
        sino sobre la generosidad de muchos, queremos agradecerte por ser
        generoso en todo tiempo y hacer que la comunidad siga avanzando.
      </Description>
      <Description>
        Depósito o transferencia <span>BBVA 4152 3136 7641 2195</span>
      </Description>
    </MainContainer>
  );
}
