import styled from "styled-components";

import Img from "../../assets/error.svg";

const MainContainer = styled.div`
  background: #f0f0f0;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
`;

const Image = styled.figure`
  width: 30px;
  height: 30px;
  margin-bottom: 15px;
`;

const Message = styled.p`
  text-align: center;
  color: #d84557;
  font-weight: 500;
`;

export default function Main(props) {
  return (
    <MainContainer>
      <Image>
        <img src={Img} alt="Error"></img>
      </Image>
      <Message>{props.children}</Message>
    </MainContainer>
  );
}
