import styled from "styled-components";
import Logo from "../../assets/logo.svg";

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px;
`;

const Image = styled.figure`
  width: 125px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export default function Main() {
  return (
    <MainContainer>
      <Image>
        <img alt="Logo" src={Logo}></img>
      </Image>
    </MainContainer>
  );
}
