import styled from "styled-components";

import backgroundSrc from "../../assets/black.jpg";

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  figure {
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
    }
  }
`;

export default function Main() {
  return (
    <MainContainer>
      <figure>
        <img src={backgroundSrc} alt="Main"></img>
      </figure>
    </MainContainer>
  );
}
