import { useState } from "react";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Button from "./atoms/Button";
import Logo from "./atoms/Logo";
import Loading from "./atoms/Loading";
import ErrorMessage from "./molecules/ErrorMessage";

import closeSrc from "../assets/close.svg";

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  box-sizing: border-box;
`;

const CenteredContainer = styled.div`
  width: 80%;
`;

const Footer = styled.p`
  margin-top: 40px;
  text-align: center;
  span {
    padding: 5px;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
  }
`;

const Close = styled.figure`
  cursor: pointer;
  position: absolute;
  top: 90px;
  left: 20px;
  width: 25px;
  height: 25px;
`;

export default function Main(props) {
  const navigate = useNavigate();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setLoadingState] = useState(false);

  const handleChange = (e) => {
    setShowErrorMessage(false);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!data.email || !data.password) {
      setLoadingState(false);
      setShowErrorMessage(true);
      setErrorMessage(
        "Ha habido un error, todos los campos tienen que estar completados."
      );
      return;
    }

    setLoadingState(true);

    let session;
    try {
      session = await Auth.signIn(data.email.trim(), data.password);
      sessionStorage.setItem("auth", true);
      sessionStorage.setItem(
        "jwt",
        session.signInUserSession.accessToken.jwtToken
      );
      setLoadingState(false);

      navigate("/profile");
    } catch (err) {
      setLoadingState(false);
      setShowErrorMessage(true);
      if (err.message === "Incorrect username or password.") {
        setErrorMessage(
          "Ha habido un error, el nombre de usuario o contraseña son incorrectos."
        );
      } else {
        setErrorMessage("Lo sentimos, ha habido un error.");
      }
    }
  };

  return (
    <MainContainer>
      {isLoading && <Loading></Loading>}
      <Close onClick={() => navigate("/")}>
        <img src={closeSrc} alt="Close"></img>
      </Close>
      <CenteredContainer>
        <Logo />
        <input
          name="email"
          type="text"
          placeholder="Correo"
          value={data.email}
          onChange={(e) => handleChange(e)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        ></input>
        <input
          name="password"
          type="password"
          placeholder="Contraseña"
          value={data.password}
          onChange={(e) => handleChange(e)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        ></input>
        {showErrorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Button handleClick={handleSubmit}>Iniciar sesión</Button>
        <Footer>
          ¿Olvidaste tu contraseña? <span>Recuperar</span>
        </Footer>
      </CenteredContainer>
    </MainContainer>
  );
}
