import styled from "styled-components";

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 20px;
  box-sizing: border-box;
  p {
    max-width: 700px;
  }
`;

const Title = styled.p`
  font-family: "League Spartan";
  font-weight: 500;
  font-size: 30px;
  color: #898989;
  text-align: center;
  margin-bottom: 30px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 19px;
  color: #464646;
  text-align: center;
`;

export default function Main() {
  return (
    <MainContainer id="about">
      <Title>
        Aviva es una comunidad que cree en Jesús, una comunidad que ama a Dios y
        a la gente.
      </Title>
      <Description>
        Agradecidos por el don de la salvación que hemos encontrado en Jesús,
        tenemos un corazón para la adoración auténtica, nos apasiona la iglesia
        local y tenemos la misión de ver el reino de Dios establecido en toda la
        tierra.
      </Description>
    </MainContainer>
  );
}
