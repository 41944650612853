import { useState } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Loading from "./atoms/Loading";
import Logo from "./atoms/Logo";
import Button from "./atoms/Button";
import ErrorMessage from "./molecules/ErrorMessage";

import closeSrc from "../assets/close.svg";

const MainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  box-sizing: border-box;
`;

const CenteredContainer = styled.div`
  width: 80%;
`;

const Close = styled.figure`
  cursor: pointer;
  position: absolute;
  top: 90px;
  left: 20px;
  width: 25px;
  height: 25px;
`;

export default function Main(props) {
  const navigate = useNavigate();
  const [isLoading, setLoadingState] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState({
    name: "",
  });

  const handleChange = (e) => {
    setShowErrorMessage(false);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!data.name || !props.credentials.email || !props.credentials.password) {
      setLoadingState(false);
      setShowErrorMessage(true);
      setErrorMessage(
        "Ha habido un error, todos los campos tienen que estar completados."
      );
      return;
    }

    setLoadingState(true);

    try {
      const user = await Auth.signUp({
        username: props.credentials.email,
        password: props.credentials.password,
        attributes: {
          email: props.credentials.email,
        },
      });

      await axios.post(process.env.REACT_APP_USERS_ENDPOINT, {
        fullname: data.name,
        email: props.credentials.email,
        cognito_sub: user.userSub,
        groupName: process.env.REACT_APP_USER_POOL_GROUP_NAME,
      });

      setLoadingState(false);

      navigate("/verify-email");
    } catch (err) {
      setLoadingState(false);
      setShowErrorMessage(true);
      if (err.message === "An account with the given email already exists.") {
        setErrorMessage(
          "Ha habido un error, el correo ingresado ya está siendo utilizado."
        );
      } else {
        setErrorMessage("Lo sentimos, ha habido un error.");
      }
    }
  };

  return (
    <MainContainer>
      {isLoading && <Loading></Loading>}
      <Close onClick={() => navigate("/")}>
        <img src={closeSrc} alt="Close"></img>
      </Close>
      <CenteredContainer>
        <Logo />
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <input
            name="name"
            type="text"
            placeholder="Nombre completo"
            value={data.name}
            minLength={8}
            onChange={(e) => handleChange(e)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          ></input>
          <input
            name="email"
            type="email"
            placeholder="Correo"
            value={props.credentials.email}
            onChange={(e) =>
              props.setCredentials(e, () => {
                setShowErrorMessage(false);
              })
            }
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          ></input>
          <input
            name="password"
            type="password"
            placeholder="Contraseña"
            minLength={8}
            value={props.credentials.password}
            onChange={(e) =>
              props.setCredentials(e, () => {
                setShowErrorMessage(false);
              })
            }
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          ></input>
          {showErrorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <Button type="submit" handleClick={handleSubmit}>
            Regístrate
          </Button>
        </form>
      </CenteredContainer>
    </MainContainer>
  );
}
