import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import closeSrc from "../../assets/close.svg";

const MainContainer = styled.div`
  padding-top: 90px;
  position: fixed;
  top: 0;
  right: 0;
  width: 380px;
  height: 100vh;
  max-width: 100%;
  background: white;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: -1px 0px 30px -5px rgba(0, 0, 0, 0.61);
`;

const Close = styled.figure`
  cursor: pointer;
  position: absolute;
  top: 90px;
  left: 20px;
  width: 25px;
  height: 25px;
`;

const MenuItem = styled.a`
  cursor: pointer;
  font-weight: 700;
  font-size: 15px;
  padding: 15px 0;
  :hover {
    color: #3ad6a0;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 20px;
`;

export default function Main(props) {
  const navigate = useNavigate();
  const [signed, setSignedState] = useState(
    sessionStorage.getItem("auth") ? true : false
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setSignedState(sessionStorage.getItem("auth") ? true : false);
    }, 300);

    return () => {
      clearInterval(timer);
    };
  });

  const signedOutButtons = (
    <>
      <MenuItem onClick={() => navigate("/login")}>INICIA SESIÓN</MenuItem>
      <MenuItem onClick={() => navigate("/signup")}>REGÍSTRATE</MenuItem>
    </>
  );

  const signedInButtons = (
    <MenuItem onClick={() => navigate("/profile")}>PERFIL</MenuItem>
  );

  return (
    <MainContainer>
      <Close onClick={() => navigate("/")}>
        <img src={closeSrc} alt="Close"></img>
      </Close>
      <Links>
        <MenuItem href="#about" onClick={() => navigate("/")}>
          SOBRE NOSOTROS
        </MenuItem>
        <MenuItem onClick={() => navigate("/")} href="#events">
          EVENTOS
        </MenuItem>
        <MenuItem onClick={() => navigate("/")} href="#help">
          FORMAS DE DAR
        </MenuItem>
        {signed ? signedInButtons : signedOutButtons}
      </Links>
    </MainContainer>
  );
}
