import { useEffect, useState } from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
`;

export default function Main(props) {
  const [width, setWidth] = useState("420");
  const [height, setHeight] = useState("240");

  useEffect(() => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  }, []);

  return (
    <MainContainer>
      <iframe
        width={width}
        height={height}
        src="https://www.youtube.com/embed/pPhO1cXB7YU"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </MainContainer>
  );
}
