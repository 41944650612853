import styled from "styled-components";

import Image from "../../assets/loading.gif";

const MainContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  z-index: 3;
`;

const Loading = styled.figure`
  width: 50px;
  height: 50px;
`;

export default function Main(props) {
  return (
    <MainContainer>
      <Loading>
        <img src={Image} alt="Loading"></img>
      </Loading>
    </MainContainer>
  );
}
