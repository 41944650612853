import styled from "styled-components";

import Post from "./Post";
import CreatePost from "./CreatePost";

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 20px;
  box-sizing: border-box;
  p {
    max-width: 700px;
  }
`;

const Title = styled.p`
  font-family: "League Spartan";
  font-weight: 700;
  font-size: 33px;
  color: black;
  text-align: center;
  margin-bottom: 30px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 15px;
  color: #464646;
  text-align: center;
  max-width: 500px !important;
  margin: 0 auto;
`;

export default function Main() {
  return (
    <MainContainer id="about">
      <Title>SOMOS UNA COMUNIDAD DE ORACIÓN</Title>
      <Description>
        Comparte en Comunidad Aviva la grandeza que Dios ha hecho en tu vida o
        escribe tu petición para que juntos oremos 🙌.
      </Description>

      <Post type="PRAY"></Post>
      <Post type="PRAISE"></Post>
      <CreatePost></CreatePost>
    </MainContainer>
  );
}
