import styled from "styled-components";

const MainContainer = styled.button`
  background: black;
  color: white;
  border-radius: 100px;
  padding: 15px 22px;
  border: none;
  outline: none;
  font-weight: 500;
`;

export default function Main(props) {
  return (
    <MainContainer type={props.type} onClick={props.handleClick}>
      {props.children}
    </MainContainer>
  );
}
