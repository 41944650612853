import { useEffect, useState } from "react";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import logoSrc from "../../assets/logoSmall.svg";
import menuSrc from "../../assets/menu.svg";

const MainContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  width: 100%;
  height: 70px;
  z-index: 2;
  box-sizing: border-box;
  background: white;

  @media (max-width: 870px) {
    padding: 0 20px;
  }
`;

const Logo = styled.figure`
  width: 82px;
  height: 23px;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    display: none;
  }
`;

const MenuItem = styled.p`
  font-weight: 700;
  margin-right: 30px;
  box-sizing: border-box;
  :hover {
    color: #3ad6a0;
    padding-bottom: 3px;
    border-bottom: 3px solid #3ad6a0;
  }
`;

const Sign = styled.button`
  outline: none;
  border: none;
  padding: 15px 20px;
  background: ${(props) => (props.profile ? "#3ad6a0" : "black")};
  text-align: center;
  color: white;
  border-radius: 100px;
  font-weight: 700;
  margin-right: ${(props) => (props.margin ? "25px" : 0)};
  :hover {
    background: #3ad6a0;
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  figure {
    width: 25px;
    height: 25px;
  }
  cursor: pointer;
  @media (min-width: 801px) {
    display: none;
  }
`;

export default function Main() {
  const [signed, setSignedState] = useState(
    sessionStorage.getItem("auth") ? true : false
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setSignedState(sessionStorage.getItem("auth") ? true : false);
    }, 300);

    return () => {
      clearInterval(timer);
    };
  });

  const navigate = useNavigate();

  const signedOutButtons = (
    <>
      <Sign onClick={() => navigate("/login")} margin={true}>
        INICIA SESIÓN
      </Sign>
      <Sign onClick={() => navigate("/signup")}>REGÍSTRATE</Sign>
    </>
  );

  const signedInButtons = (
    <Sign onClick={() => navigate("/profile")} profile={true}>
      PERFIL
    </Sign>
  );

  return (
    <MainContainer>
      <Logo>
        <img src={logoSrc} alt="Logo"></img>
      </Logo>
      <RightContainer>
        <a href="#about" onClick={() => navigate("/")}>
          <MenuItem>SOBRE NOSOTROS</MenuItem>
        </a>
        <a href="#events" onClick={() => navigate("/")}>
          <MenuItem>EVENTOS</MenuItem>
        </a>
        <a href="#help" onClick={() => navigate("/")}>
          <MenuItem>FORMAS DE DAR</MenuItem>
        </a>
        {signed ? signedInButtons : signedOutButtons}
      </RightContainer>
      <Menu onClick={() => navigate("/menu")}>
        <MenuItem>MENU</MenuItem>
        <figure>
          <img src={menuSrc} alt="Menu"></img>
        </figure>
      </Menu>
    </MainContainer>
  );
}
