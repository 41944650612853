import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";

import Access from "./components/Access";
import Profile from "./components/Profile";
import VerifyEmail from "./components/VerifyEmail";
import Live from "./components/atoms/Live";
import Website from "./components/Website";
import Menu from "./components/molecules/Menu";

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function App(props) {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e, callback) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    if (callback) {
      callback();
    }
  };

  const resetCredentials = () => {
    setCredentials({
      email: "",
      password: "",
    });
  };

  return (
    <MainContainer>
      <Router>
        <Routes>
          <Route path="/" element={<Website></Website>}></Route>
          <Route
            path="/login"
            element={
              <>
                <Access auth={true}></Access>
                <Website></Website>
              </>
            }
          ></Route>
          <Route
            path="/signup"
            element={
              <>
                <Access
                  auth={false}
                  credentials={credentials}
                  setCredentials={handleChange}
                ></Access>
                <Website></Website>
              </>
            }
          ></Route>
          <Route
            path="/verify-email"
            element={
              <>
                <VerifyEmail
                  credentials={credentials}
                  resetCredentials={resetCredentials}
                ></VerifyEmail>
                <Website></Website>
              </>
            }
          ></Route>
          <Route
            path="/menu"
            element={
              <>
                <Menu></Menu>
                <Website></Website>
              </>
            }
          ></Route>
          <Route
            path="/profile"
            element={
              <>
                <Profile></Profile>
                <Website></Website>
              </>
            }
          ></Route>
          <Route path="/live" element={<Live></Live>}></Route>
        </Routes>
      </Router>
    </MainContainer>
  );
}
