import { useState } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import Loading from "./atoms/Loading";
import Logo from "./atoms/Logo";
import Button from "./atoms/Button";

const MainContainer = styled.div`
  padding-top: 90px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 450px;
  height: 100vh;
  max-width: 100%;
  background: white;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: -1px 0px 30px -5px rgba(0, 0, 0, 0.61);
`;

const CenteredContainer = styled.div`
  width: 80%;
`;

const Fixed = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Label = styled.p`
  text-align: center;
  margin: 20px;
`;

export default function Main(props) {
  const navigate = useNavigate();
  const [isLoading, setLoadingState] = useState(false);
  const [code, setCodeState] = useState("");

  const handleChange = (e) => {
    setCodeState(e.target.value);
  };

  return (
    <MainContainer>
      {isLoading && <Loading></Loading>}
      <Fixed>
        <Button handleClick={() => navigate("/login")}>Iniciar sesión</Button>
      </Fixed>
      <CenteredContainer>
        <Logo />
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Label>
            Hemos enviado un código de verificación al correo especificado con
            la finalidad de validar tu cuenta.
          </Label>
          <input
            name="code"
            type="text"
            placeholder="Código de verificación"
            value={code}
            minLength={4}
            onChange={(e) => handleChange(e)}
          ></input>
          <Button
            handleClick={async () => {
              setLoadingState(true);

              await Auth.confirmSignUp(props.credentials.email, code);

              const session = await Auth.signIn(
                props.credentials.email.trim(),
                props.credentials.password
              );
              sessionStorage.setItem(
                "jwt",
                session.signInUserSession.accessToken.jwtToken
              );
              sessionStorage.setItem("auth", true);

              props.resetCredentials();

              setLoadingState(false);

              navigate("/profile");
            }}
          >
            Verificar cuenta
          </Button>
        </form>
      </CenteredContainer>
    </MainContainer>
  );
}
