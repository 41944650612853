import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Login from "./Login";
import Signup from "./Signup";

const MainContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 450px;
  height: 100vh;
  max-width: 100%;
  background: white;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: -1px 0px 30px -5px rgba(0, 0, 0, 0.61);
`;

export default function Main(props) {
  const navigate = useNavigate();

  return (
    <MainContainer>
      {props.auth ? (
        <Login
          changeSection={() => {
            navigate("/signup");
          }}
          {...props}
        ></Login>
      ) : (
        <Signup
          changeSection={() => {
            navigate("/login");
          }}
          {...props}
        ></Signup>
      )}
    </MainContainer>
  );
}
