import styled from "styled-components";

import instagramSrc from "../../assets/instagram.svg";
import facebookSrc from "../../assets/facebook.svg";

const MainContainer = styled.div`
  width: 100%;
  height: 70px;
  padding: 0 50px;
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 15px;
    text-align: center;
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const Containers = styled.div`
  display: flex;
  a {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
  figure {
    cursor: pointer;
  }
  width: 100px;

  @media (max-width: 700px) {
    padding: 15px 0;
  }
`;

export default function Main() {
  return (
    <MainContainer>
      <Containers>
        <a
          href="https://www.facebook.com/Avivaenlinea"
          target="_blank"
          rel="noreferrer"
        >
          <figure>
            <img src={facebookSrc} alt="Social Networks"></img>
          </figure>
        </a>
        <a
          href="https://www.instagram.com/comunidad_aviva/"
          target="_blank"
          rel="noreferrer"
        >
          <figure>
            <img src={instagramSrc} alt="Social Networks"></img>
          </figure>
        </a>
      </Containers>
      <p>Comunidad Aviva todos los derechos reservados 2021</p>
      <Containers></Containers>
    </MainContainer>
  );
}
