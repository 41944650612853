import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import axios from "axios";

import Button from "./atoms/Button";
import Logo from "./atoms/Logo";
import { useEffect, useState } from "react";

import closeSrc from "../assets/close.svg";

const MainContainer = styled.div`
  padding-top: 90px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 450px;
  height: 100vh;
  max-width: 100%;
  background: white;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: -1px 0px 30px -5px rgba(0, 0, 0, 0.61);
`;

const CenteredContainer = styled.div`
  width: 80%;
`;

const Fixed = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

const Table = styled.div`
  border: 1px solid gray;
  div:last-child {
    border-bottom: none;
  }
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid gray;
  p {
    padding: 18px 0 18px 20px;
    box-sizing: border-box;
  }
`;

const Header = styled.p`
  font-weight: 500;
  border-right: 1px solid gray;
  width: 100px;
  min-width: 100px;
`;

const Text = styled.p`
  overflow-x: scroll;
  white-space: nowrap;
`;

// const PaymentStatus = styled.p`
//   border-radius: 100px;
//   background: #86b2cb;
//   text-align: center;
//   font-weight: 500;
//   color: white;
//   height: 25px;
//   display: flex;
//   align-items: center;
//   padding: 15px 20px !important;
//   margin-left: 18px;
//   margin-top: 11px;
// `;

const Transmission = styled.button`
  background: #86b2cb;
  border-radius: 5px;
  color: white;
  margin-top: 40px;
  border: none;
  padding: 20px 30px;
  font-weight: 500;
  span {
    color: red;
  }
`;

const Close = styled.figure`
  cursor: pointer;
  position: absolute;
  top: 90px;
  left: 20px;
  width: 25px;
  height: 25px;
`;

export default function Main(props) {
  const navigate = useNavigate();
  const [user, setUserStata] = useState(null);
  // const [paymentStatus, setPaymentStatus] = useState(false);

  useEffect(() => {
    // axios({
    //   method: "get",
    //   url: process.env.REACT_APP_PAID_EVENTS_ENDPOINT,
    //   headers: {
    //     Authorization: sessionStorage.getItem("jwt"),
    //   },
    // }).then((response) => {
    //   setPaymentStatus(JSON.parse(response.data.body).data.status);
    // });

    axios({
      method: "get",
      url: process.env.REACT_APP_USERS_ENDPOINT,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    }).then((response) => {
      setUserStata(JSON.parse(response.data.body).data);
    });
  }, []);

  // let paymentLabel;
  // switch (paymentStatus) {
  //   case "PAID":
  //     paymentLabel = "Pagado";
  //     break;
  //   case "TEMP-PAYMENT":
  //     paymentLabel = "Apartado";
  //     break;
  //   default:
  //     paymentLabel = "Pendiente";
  // }

  return (
    <MainContainer>
      <Close onClick={() => navigate("/")}>
        <img src={closeSrc} alt="Close"></img>
      </Close>
      <CenteredContainer>
        <Logo />
        <Table>
          <Row>
            <Header>Nombre</Header>
            <Text>{user ? user.fullname : ""}</Text>
          </Row>
          <Row>
            <Header>Evento</Header>
            <Text>Consumado es</Text>
          </Row>
          <Row>
            <Header>Fecha</Header>
            <Text>15 y 16 de Abril 2022</Text>
          </Row>
          <Row>
            <Header>Ubicación</Header>
            <Text>Avenida del parque #45 Cd. Del Valle</Text>
          </Row>
        </Table>
        <Link to="/live" target="_blank">
          <Transmission>
            <span>❤️ </span> Únete a la transmisión
          </Transmission>
        </Link>
      </CenteredContainer>
      <Fixed>
        <Button
          handleClick={async () => {
            navigate("/");
            await Auth.signOut({ global: true });
            sessionStorage.clear();
            localStorage.clear();
          }}
        >
          Cerrar sesión
        </Button>
      </Fixed>
    </MainContainer>
  );
}
