import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import backgroundSrc from "../../assets/background.jpg";
import lettersSrc from "../../assets/letters.png";

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  box-sizing: border-box;
  background-image: url(${backgroundSrc});
  background-size: cover;
`;

const Title = styled.p`
  font-family: "Pacifico";
  font-weight: 500;
  font-size: 35px;
  color: white;
  text-align: center;
  margin: 30px auto;
`;

const NextEvent = styled.figure`
  width: 500px;
  max-width: 100%;
  margin: 50px auto;
`;

const CTA = styled.button`
  font-weight: 500;
  background: #3ad6a0;
  color: white;
  border-radius: 50px;
  text-align: center;
  padding: 15px 25px;
  margin-bottom: 70px;
  border: none;
  outline: none;
`;

export default function Main() {
  const navigate = useNavigate();

  return (
    <MainContainer id="events">
      <Title>Eventos y Conferencias</Title>
      <NextEvent>
        <img src={lettersSrc} alt="Next event"></img>
      </NextEvent>
      <CTA
        onClick={() => {
          if (sessionStorage.getItem("auth")) {
            navigate("/profile");
          } else {
            navigate("/login");
          }
        }}
      >
        Ver la transmisión 🚀
      </CTA>
    </MainContainer>
  );
}
