import styled from "styled-components";

const MainContainer = styled.div`
  border-radius: 5px;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.4);
  width: 180px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
`;

const Header = styled.div`
  display: flex;
`;

const Tab = styled.button`
  border: none;
  outline: none;
  border-top: ${(props) =>
    props.type === "PRAY" ? "7px solid #3ad6a0" : "7px solid #144361"};
`;

export default function Post(props) {
  return (
    <MainContainer>
      <Header>
        <Tab type="PRAY">Oración</Tab>
        <Tab type="PRAISE">Testimonio</Tab>
      </Header>
    </MainContainer>
  );
}
