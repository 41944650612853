import styled from "styled-components";

import Header from "./molecules/Header";
import Background from "./atoms/Background";
import AboutUs from "./molecules/AboutUs";
import Events from "./molecules/Events";
import Help from "./molecules/Help";
import Wall from "./molecules/Wall";
import Footer from "./molecules/Footer";

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
`;

export default function Main() {
  return (
    <MainContainer>
      <Header></Header>
      <Background></Background>
      <AboutUs></AboutUs>
      <Events></Events>
      {/* <Wall></Wall> */}
      <Help></Help>
      <Footer></Footer>
    </MainContainer>
  );
}
