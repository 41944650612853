import styled from "styled-components";

const MainContainer = styled.div`
  border-radius: 5px;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.4);
  width: 180px;
  border-top: ${(props) =>
    props.type === "PRAY" ? "7px solid #3ad6a0" : "7px solid #144361"};
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
`;

const Owner = styled.p`
  font-weight: 500;
  margin-bottom: 5px;
`;

const Description = styled.p``;

export default function Post(props) {
  return (
    <MainContainer type={props.type}>
      <Owner>Anónimo</Owner>
      <Description>
        Esta es una oración de algún hermano por ahí, hay que ver la cantidad de
        caracteres disponibles. Mas texto por acá para ver como se vé, seguiré
        escribiendo cosas random y veamos que pasa.
      </Description>
    </MainContainer>
  );
}
